import { lazy } from "react";
import { IRouteProps } from "shared/types/routes";

const Achievements = lazy(() => import("creators/pages/Achievements"));
const ActiveBrief = lazy(() => import("creators/pages/ActiveBrief"));
const ActiveBriefPreview = lazy(
  () => import("creators/pages/ActiveBriefPreview"),
);
const HomePage = lazy(() => import("creators/pages/Home"));
const Payments = lazy(() => import("creators/pages/Payments"));
const PreviousBrief = lazy(() => import("creators/pages/PreviousBrief"));
const PreviousBriefs = lazy(() => import("creators/pages/PreviousBriefs"));
const Profile = lazy(() => import("creators/pages/Profile"));
const SettingsPage = lazy(() => import("creators/pages/Settings"));
const VUIPaymentsPage = lazy(() => import("creators/pages/vui/Payments"));
const VUISettingsPage = lazy(() => import("creators/pages/vui/Settings"));
const VUIPreviousBriefs = lazy(
  () => import("creators/pages/vui/PreviousBriefs"),
);
const VUIProfilePage = lazy(() => import("creators/pages/vui/Profile"));
const VUIHomePage = lazy(() => import("creators/pages/vui/Home"));
const VUIActiveBriefPreview = lazy(
  () => import("creators/pages/vui/ActiveBriefPreview"),
);
const VUIActiveBrief = lazy(() => import("creators/pages/vui/ActiveBrief"));

const routes: IRouteProps[] = [
  {
    component: HomePage,
    exact: true,
    path: [
      "/",
      "/brief/:id(\\d+)/:slug?",
      "/brief/:id(\\d+)/teaser/:previewHash",
      "/:page?(onboarding|terms-and-conditions|verify-email)",
    ],
    updatedComponent: VUIHomePage,
  },
  {
    component: Achievements,
    exact: true,
    path: "/achievements",
    verificationRequired: true,
  },
  {
    component: ActiveBrief,
    exact: true,
    path: [
      "/active-brief",
      "/active-brief/:page(video-\\d+)",
      "/active-brief/release-forms",
      "/active-brief/submit",
    ],
    verificationRequired: true,
    updatedComponent: VUIActiveBrief,
  },
  {
    component: ActiveBriefPreview,
    exact: true,
    path: [
      "/brief/:id(\\d+)/full/:previewHash",
      "/brief/:id(\\d+)/full/:previewHash/:page(video-\\d+)",
    ],
    verificationRequired: true,
    updatedComponent: VUIActiveBriefPreview,
  },
  {
    component: Payments,
    exact: true,
    path: "/payments",
    updatedComponent: VUIPaymentsPage,
    verificationRequired: true,
  },
  {
    component: PreviousBrief,
    exact: true,
    path: "/previous-briefs/:id(\\d+)/:slug?",
    verificationRequired: true,
  },
  {
    component: PreviousBriefs,
    exact: true,
    path: "/previous-briefs",
    updatedComponent: VUIPreviousBriefs,
    verificationRequired: true,
  },
  {
    component: Profile,
    exact: true,
    path: ["/profile", "/profile/:id"],
    verificationRequired: true,
    updatedComponent: VUIProfilePage,
  },
  {
    component: SettingsPage,
    path: ["/settings", "/settings/:page?(account|payment|profile)"],
    updatedComponent: VUISettingsPage,
    verificationRequired: true,
  },
];

export default routes;
