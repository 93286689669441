import BriefGrid from "creators/components/BriefGrid";
import DataError from "creators/components/DataError";
import LoadingDelayed from "creators/components/LoadingDelayed";
import ANIMATIONS from "creators/constants/animations";
import { GET_LIVE_BRIEFS } from "creators/queries/GetLiveBriefs";
import smallTextStyles from "creators/styling/SmallText";
import {
  BriefType,
  GetLiveBriefsQuery as IGetLiveBriefs,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { ReactNode, useCallback, useContext, useState } from "react";
import { ToastContext } from "shared/components/ToastProvider";
import useLoadMoreQuery from "shared/hooks/useLoadMoreQuery";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import useReduceMotion from "shared/hooks/useReduceMotion";
import styled from "styled-components";

interface IProps {
  className?: string;
  heading: string;
  id: string;
  maxPerPage: number;
  noResults: ReactNode | string;
  type: BriefType;
}

const Heading = styled.h2`
  margin-bottom: var(--v-spacing-4);
`;

const NoResults = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    align-self: center;
    max-width: 33.3125rem;
  }

  > span {
    ${smallTextStyles}
  }
`;

function BriefsSection({
  className,
  heading,
  id,
  maxPerPage: MAX_PER_PAGE,
  noResults,
  type,
}: IProps) {
  const { addToast } = useContext(ToastContext);
  const [moreBriefsLoading, setMoreBriefsLoading] = useState(false);
  const prefersReducedMotion = useReduceMotion();

  const { AnimationComponent } = useLottieAnimation({
    animation:
      type === BriefType.MotionGraphics
        ? ANIMATIONS.NO_REMIX_BRIEFS
        : ANIMATIONS.NO_CGC_BRIEFS,
    disabled: prefersReducedMotion,
  });

  const { data, loading, error, loadMore } = useLoadMoreQuery<IGetLiveBriefs>(
    GET_LIVE_BRIEFS,
    MAX_PER_PAGE,
    {
      extraVariables: {
        type,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  );

  const loadMoreBriefs = useCallback(async () => {
    try {
      setMoreBriefsLoading(true);

      await loadMore();
    } catch {
      addToast(textualize("error.loadMore") as string, true);
    } finally {
      setMoreBriefsLoading(false);
    }
  }, [addToast, loadMore]);

  let content;
  if (error) {
    content = <DataError error={error} />;
  } else if (loading) {
    content = <LoadingDelayed />;
  } else if (!data?.liveBriefsV2?.edges || data.liveBriefsV2.edges.length < 1) {
    content = (
      <NoResults id={id}>
        {prefersReducedMotion && <span>{noResults}</span>}

        <div aria-label={noResults as string} role="img">
          {AnimationComponent}
        </div>
      </NoResults>
    );
  } else {
    const moreBriefsAvailable = data.liveBriefsV2.pageInfo.hasNextPage;

    const briefs = data.liveBriefsV2.edges.map(({ node }) => {
      const {
        brand,
        creatorSelectionDate,
        id: briefID,
        paymentAmount,
        slug,
        state,
        submission,
        summary,
        title,
        type,
      } = node;

      return {
        brandImage: brand.imageURI,
        brandName: brand.name,
        brandID: brand.id,
        briefID,
        briefState: state,
        briefSummary: summary,
        briefTitle: title,
        briefType: type,
        creatorSelectionDate:
          creatorSelectionDate && new Date(creatorSelectionDate),
        paymentAmount: paymentAmount.amount,
        paymentCurrencyCode: paymentAmount.currencyCode,
        slug,
        submissionState: submission ? submission.state : undefined,
        submittedAt: submission?.submittedAt
          ? new Date(submission.submittedAt)
          : undefined,
      };
    });

    content = (
      <BriefGrid
        briefs={briefs}
        id={id}
        loading={loading || moreBriefsLoading}
        loadMore={loadMoreBriefs}
        moreBriefsAvailable={moreBriefsAvailable}
      />
    );
  }

  return (
    <div className={className}>
      <Heading>{heading}</Heading>
      {content}
    </div>
  );
}

export default BriefsSection;
