import { css } from "styled-components";

const typography = css`
  --vui-brand-font: "Eina 01", sans-serif;
  --vui-headline-font: "Eina 01", sans-serif;
  --vui-title-font: "Eina 01", sans-serif;
  --vui-plain-font: system-ui;

  --vui-ref-typeface-brand: var(--vui-brand-font);
  --vui-ref-typeface-plain: var(--vui-plain-font);

  --vui-sys-typescale-headline-font: var(--vui-headline-font);
  --vui-sys-typescale-title-font: var(--vui-title-font);

  --vui-typescale-display-large-font: var(--vui-ref-typeface-brand);
  --vui-typescale-display-medium-font: var(--vui-ref-typeface-brand);
  --vui-typescale-display-small-font: var(--vui-ref-typeface-brand);
  --vui-typescale-display-large-weight: 400;
  --vui-typescale-display-medium-weight: 400;
  --vui-typescale-display-small-weight: 400;
  --vui-typescale-display-large-line-height: 4rem;
  --vui-typescale-display-medium-line-height: 3.25rem;
  --vui-typescale-display-small-line-height: 2.75rem;
  --vui-typescale-display-large-size: 3.5625rem;
  --vui-typescale-display-medium-size: 2.8125rem;
  --vui-typescale-display-small-size: 2.25rem;
  --vui-typescale-display-large-tracking: 0;
  --vui-typescale-display-medium-tracking: 0;
  --vui-typescale-display-small-tracking: 0;

  --vui-typescale-headline-large-font: var(--vui-ref-typeface-brand);
  --vui-typescale-headline-medium-font: var(--vui-ref-typeface-brand);
  --vui-typescale-headline-small-font: var(--vui-ref-typeface-brand);
  --vui-typescale-headline-large-weight: 400;
  --vui-typescale-headline-medium-weight: 400;
  --vui-typescale-headline-small-weight: 600;
  --vui-typescale-headline-large-line-height: 2.5rem;
  --vui-typescale-headline-medium-line-height: 2.25rem;
  --vui-typescale-headline-small-line-height: 2rem;
  --vui-typescale-headline-large-size: 2rem;
  --vui-typescale-headline-medium-size: 1.75rem;
  --vui-typescale-headline-small-size: 1.5rem;
  --vui-typescale-headline-large-tracking: 0;
  --vui-typescale-headline-medium-tracking: 0;
  --vui-typescale-headline-small-tracking: 0;

  --vui-typescale-title-large-font: var(--vui-ref-typeface-brand);
  --vui-typescale-title-medium-font: var(--vui-ref-typeface-brand);
  --vui-typescale-title-small-font: var(--vui-ref-typeface-brand);
  --vui-typescale-title-large-weight: 600;
  --vui-typescale-title-medium-weight: 600;
  --vui-typescale-title-small-weight: 600;
  --vui-typescale-title-large-line-height: 1.75rem;
  --vui-typescale-title-medium-line-height: 1.5rem;
  --vui-typescale-title-small-line-height: 1.25rem;
  --vui-typescale-title-large-size: 1.375rem;
  --vui-typescale-title-medium-size: 1.125rem;
  --vui-typescale-title-small-size: 0.875rem;
  --vui-typescale-title-large-tracking: 0;
  --vui-typescale-title-medium-tracking: 0.15;
  --vui-typescale-title-small-tracking: 0.1;

  --vui-typescale-label-large-font: var(--vui-ref-typeface-brand);
  --vui-typescale-label-medium-font: var(--vui-ref-typeface-brand);
  --vui-typescale-label-small-font: var(--vui-ref-typeface-brand);
  --vui-typescale-label-large-weight: 500;
  --vui-typescale-label-medium-weight: 500;
  --vui-typescale-label-small-weight: 500;
  --vui-typescale-label-large-line-height: 1.25rem;
  --vui-typescale-label-medium-line-height: 1rem;
  --vui-typescale-label-small-line-height: 0.375rem;
  --vui-typescale-label-large-size: 0.875rem;
  --vui-typescale-label-medium-size: 0.75rem;
  --vui-typescale-label-small-size: 0.6875rem;
  --vui-typescale-label-large-tracking: 0.1;
  --vui-typescale-label-medium-tracking: 0.5;
  --vui-typescale-label-small-tracking: 0.5;

  --vui-typescale-body-large-font: var(--vui-ref-typeface-brand);
  --vui-typescale-body-medium-font: var(--vui-ref-typeface-brand);
  --vui-typescale-body-small-font: var(--vui-ref-typeface-brand);
  --vui-typescale-body-large-weight: 400;
  --vui-typescale-body-medium-weight: 400;
  --vui-typescale-body-small-weight: 400;
  --vui-typescale-body-large-line-height: 1.5rem;
  --vui-typescale-body-medium-line-height: 1.25rem;
  --vui-typescale-body-small-line-height: 1rem;
  --vui-typescale-body-large-size: 1rem;
  --vui-typescale-body-medium-size: 0.875rem;
  --vui-typescale-body-small-size: 0.75rem;
  --vui-typescale-body-large-tracking: 0.15;
  --vui-typescale-body-medium-tracking: 0.25;
  --vui-typescale-body-small-tracking: 0.4;
`;

export default typography;
