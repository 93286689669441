import { ApolloClient, useQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { DocumentNode } from "graphql";
import getPaginatedData from "shared/utils/getPaginatedData";

interface IOptions {
  client?: ApolloClient<unknown>;
  extraVariables?: any;
  fetchPolicy?: WatchQueryFetchPolicy;
  nextFetchPolicy?: WatchQueryFetchPolicy;
  pathToPagination?: string[];
  withInput?: boolean;
}

const useLoadMoreQuery = <Q>(
  query: DocumentNode,
  max: number,
  options: IOptions = {},
) => {
  const {
    client,
    extraVariables,
    fetchPolicy,
    nextFetchPolicy,
    pathToPagination,
    withInput,
  } = options;

  const getVariables = (max: number, cursor?: string) => {
    const variables = {
      after: cursor,
      first: max,
      ...extraVariables,
    };

    if (withInput) {
      return {
        input: variables,
      };
    }

    return variables;
  };

  const { data, error, fetchMore, loading } = useQuery<Q>(query, {
    client,
    fetchPolicy,
    nextFetchPolicy,
    variables: getVariables(max, undefined),
  });

  const loadMore = () => {
    let lastCursor;

    if (data) {
      const actualData = getPaginatedData(data, pathToPagination);
      const lastKey = actualData.edges.length - 1;

      lastCursor = actualData.edges![lastKey].cursor;
    }

    return fetchMore({
      variables: getVariables(max, lastCursor),
    });
  };

  return {
    data,
    error,
    loading,
    loadMore,
  };
};

export default useLoadMoreQuery;
