import creatorsTextualize from "creators/utils/textualize";
import debounce from "lodash/debounce";
import flatten from "lodash/flatten";
import { marked } from "marked";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Block, BlockProperties } from "shared/BlockBuilder/global";
import { textualize } from "shared/BlockBuilder/utils";
import KEYBOARD_SHORTCUTS from "shared/constants/shortcuts/keyboardShortcuts";
import useFeature from "shared/hooks/useFeature";
import { Container, ExpandingTextarea, Header } from "./styles";

export interface IProps {
  block: Block;
  className?: string;
  editable?: boolean;
  elType?: string;
  error?: string;
  handleChange?: (value: { properties: BlockProperties }) => void;
  id: string;
}

function BriefMustInclude({
  block,
  elType = "ul",
  handleChange,
  id,
  ...rest
}: IProps) {
  const creatorsVUIFlag = useFeature("creatorsVUI");

  const ListType = elType as keyof JSX.IntrinsicElements;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [focusInput, setFocusInput] = useState<number | undefined>();

  const generateItems = () => {
    const value = Array.isArray(block.properties.value)
      ? flatten(block.properties.value) // TODO: Replace with Array.flat once lib compiler updated
      : [block.properties.value].filter((val) => val !== undefined);

    if (value.length === 0) {
      return [""];
    }

    return value;
  };

  const editable = !!handleChange;

  // Local cache of items to allow for debouncing
  const [items, setItems] = useState(generateItems());

  const debouncedHandleChange = useMemo(
    () => debounce(editable ? handleChange : () => null, 250),
    [editable, handleChange],
  );

  useEffect(() => {
    debouncedHandleChange({
      properties: {
        value: items,
      },
    });
  }, [debouncedHandleChange, items]);

  const onItemUpdate = useCallback(
    (value: string, index: number) => {
      const newValue = [...items];
      newValue[index] = value;

      setItems(newValue);
    },
    [items],
  );

  const onEnter = useCallback(
    (index: number) => {
      const newValue = [...items];
      newValue.splice(index + 1, 0, "");

      setItems(newValue);
      setFocusInput(index + 1);
    },
    [items],
  );

  const onDelete = useCallback(
    (index: number) => {
      const newValue = [...items];
      newValue.splice(index, 1);
      setItems(newValue);
      setFocusInput(index - 1 < 0 ? 0 : index - 1);
    },
    [items],
  );

  const keyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>, index: number) => {
      if (e.key === KEYBOARD_SHORTCUTS.ENTER && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        onEnter(index);
      } else if (
        e.key === KEYBOARD_SHORTCUTS.BACKSPACE &&
        e.currentTarget.value === "" &&
        items.length > 1
      ) {
        e.preventDefault();
        onDelete(index);
      }
    },
    [items.length, onDelete, onEnter],
  );

  useEffect(() => {
    if (inputRef.current && focusInput !== undefined) {
      inputRef.current.focus();
      setFocusInput(undefined);
    }
  }, [focusInput]);

  return (
    <Container $vui={creatorsVUIFlag} id={id} {...rest}>
      <Header $vui={creatorsVUIFlag}>
        {creatorsTextualize("blocks.mustInclude")}
      </Header>
      <ListType>
        {items.map((item, index) =>
          editable ? (
            <li key={`${block.id}-${index}`}>
              <ExpandingTextarea
                aria-label={textualize("briefMustInclude.inputLabel") as string}
                id={`${id}_${index}`}
                onChange={(e) => onItemUpdate(e.currentTarget.value, index)}
                onKeyDown={(e) => keyDownHandler(e, index)}
                ref={index === focusInput ? inputRef : undefined}
                value={Array.isArray(item) ? item.join(" ") : item}
              />
            </li>
          ) : (
            <li id={`${id}_${index}`} key={`${id}-${index}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(Array.isArray(item) ? item.join(" ") : item),
                }}
              ></div>
            </li>
          ),
        )}
      </ListType>
    </Container>
  );
}

export default BriefMustInclude;
