import textualize from "vui/utils/textualize";
import {
  NavRailContainer,
  NavRailContent,
  NavRailContentItemBadge,
  NavRailContentItemIcon,
  NavRailContentItemLabel,
  NavRailContentItemOuter,
  NavRailFooter,
  NavRailHeader,
} from "./styles";

interface INavRailItemProps {
  active?: boolean;
  children?: React.ReactNode;
  className?: string;
  notification?: boolean;
  icon: React.FC;
}

// TODO: Create equivalent for react router links
const NavRailItem: React.FC<React.PropsWithChildren<INavRailItemProps>> = ({
  active,
  children,
  className,
  notification,
  icon,
}) => {
  return (
    <NavRailContentItemOuter active={active} className={className}>
      <NavRailContentItemIcon component={icon} />
      {notification && (
        <NavRailContentItemBadge
          aria-label={textualize("navigation.notification") as string}
        />
      )}
      <NavRailContentItemLabel>{children}</NavRailContentItemLabel>
    </NavRailContentItemOuter>
  );
};

export {
  NavRailContainer,
  NavRailContent,
  NavRailFooter,
  NavRailHeader,
  NavRailItem,
};
